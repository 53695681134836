import { onMounted, ref, defineComponent, reactive, toRefs } from 'vue';
import { areaList } from '@vant/area-data';
import { useRoute } from 'vue-router'; // import { getAuthLoginUrl } from '@/api/main'
// , doWebLogin

import { setToken } from '@/utils/token';
import wx from 'weixin-js-sdk'; // import { chooseWXPay } from '@/utils/wxsdk'

import { getGoodsInfo, dobuyCarCode } from '@/api/shop';
import { Toast } from 'vant';
import 'vant/es/toast/style';
export default defineComponent({
  name: 'Shop',
  components: {},

  setup() {
    const state = reactive({
      formRef: null,
      form: {
        name: '',
        tel: '',
        area: '',
        detail: ''
      },
      goods: {},
      total_price: 0,
      loadComplete: false
    });
    const route = useRoute(); // const router = useRouter()

    const validator = val => {
      const reg = /^1\d{10}$/;
      return reg.test(val);
    };

    const showArea = ref(false);

    const onConfirm = areaValues => {
      showArea.value = false;
      state.form.area = areaValues.filter(item => !!item).map(item => item.name).join('');
    };
    /**
     * 提交订单跳转到小程序内支付
     */


    const onSubmit = () => {
      state.formRef.validate().then(async () => {
        const toast = Toast.loading({
          message: '加载中...',
          forbidClick: true
        });
        const {
          data,
          status,
          msg
        } = await dobuyCarCode({
          name: state.form.name,
          tel: state.form.tel,
          area: state.form.area,
          detail: state.form.detail
        });
        toast.clear();

        if (status == 1) {
          if (data.need_pay) {
            // 需要支付
            wx.miniProgram.postMessage({
              data: {
                payment: data.payment,
                msg: '已下单,将立即为您安排发货,请耐心等待!',
                type: 'shop'
              }
            });
            wx.miniProgram.reLaunch({
              url: '/pages/payment/index'
            }); // chooseWXPay(
            //   Object.assign(data.payment, {
            //     success: () => {
            //       Toast.success('已支付')
            //     },
            //   })
            // )
          } else {
            Toast.success('已下单');
          }
        } else {
          Toast.fail(msg);
        }
      }).catch(() => {});
    }; // const onSubmit = () => {
    //   state.formRef
    //     .validate()
    //     .then(async () => {
    //       const toast = Toast.loading({
    //         message: '加载中...',
    //         forbidClick: true,
    //       })
    //       const { data, status, msg } = await dobuyCarCode({
    //         name: state.form.name,
    //         tel: state.form.tel,
    //         area: state.form.area,
    //         detail: state.form.detail,
    //       })
    //       toast.clear()
    //       if (status == 1) {
    //         if (data.need_pay) {
    //           // 需要支付
    //           // let a = 1
    //           // if (a == 1) {
    //           //   return false
    //           // }
    //           chooseWXPay(
    //             Object.assign(data.payment, {
    //               success: () => {
    //                 Toast.success('已支付')
    //               },
    //             })
    //           )
    //         } else {
    //           Toast.success('已下单')
    //         }
    //       } else {
    //         Toast.fail(msg)
    //       }
    //       console.log(data)
    //     })
    //     .catch(() => {})
    // }


    const fetchData = async () => {
      const toast = Toast.loading({
        message: '加载中...',
        forbidClick: true
      });
      const {
        data
      } = await getGoodsInfo();
      toast.clear();
      state.goods = data;
      state.total_price = Number(data.express_price) * 100 + Number(data.goods_price) * 100;
      state.loadComplete = true;
    };

    onMounted(async () => {
      const query = route.query;
      const token = query.token;

      if (!token) {
        return;
      }

      await setTokenInit(token);
      fetchData(); // if (token) {
      //   const data = await getAuthLoginUrl()
      //   location.replace(data.data.url)
      // } else {
      //   fetchData()
      // }
    });

    const setTokenInit = token => {
      return new Promise(resolve => {
        setToken(token);
        resolve();
      });
    };

    return { ...toRefs(state),
      showArea,
      areaList,
      onConfirm,
      onSubmit,
      validator
    };
  }

});